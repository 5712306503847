export const apiPath = {
  auth: {
    login: "/v1/auth/login",
    refreshToken: "/v1/auth/validateRefreshToken",
    logout: "/v1/auth/logout",
    getCurrentUser: "/v1/auth/current",
    signUp: {
      checkIsDuplicateMobileOrEmail: "/v1/users/check/email-phone/exist",
      verifyOTP: '/v1/auth/verify-user/otp',
      resendOTP: '/v1/auth/resend-user/otp',
      checkIsDuplicateCompanyName: '/v1/auth/check/company-name/exist',
      signUpUser: '/v1/auth/signup',
      uploadImage: '/v1/user-files/upload-profileImage',
      reportIssue: '/v1/users/report-issue',
      termsConditions: '/v1/auth/accept/terms-conditions'
    }
  },
  agpAI: "/v1/agpai",
  brands: {
    createBrand: "/v1/brands/",
    getAllBrands: "/v1/brands/",
    updateBrand: "/v1/brands/",
    removeBrand: "/v1/brands/",
    getBrandByID: "/v1/brands/",
    searchBrands: "/v1/brands/search/",
    filterBrands: "/v1/brands/filter/",
    downloadBrands: "/v1/brands/download-excel",
  },

  brand_requirements: {
    getAllPublicBrandRequirements: "v1/brand_requirements/public",
    createBrandRequirement: "/v1/brand_requirements/bulk/",
    getAllBrandRequirements: "/v1/brand_requirements",
    updateBrandRequirement: "/v1/brand_requirements/",
    removeBrandRequirement: "/v1/brand_requirements/",
    getBrandRequirementByID: "/v1/brand_requirements/",
    searchBrandRequirements: "/v1/brand_requirements/search/",
    filterBrandRequirements: "/v1/brand_requirements/public/filter",
    downloadBrandRequirements: "/v1/brand_requirements/download-excel",
    getAllBrandRequirementsByBrandId: "/v1/brand_requirements/brand/",
    getAllMatchingProperties: "/v1/brand_requirements/matching-properties/",
    createMatchingProperties: "/v1/matching_property_requests",
    createSubmitProperty: "/v1/broker_requirement_properties",
    getSubmittedPropertyByBrandReq:
      "/v1/broker_requirement_properties/requirement/",
    getAllSubmittedProperty: "/v1/broker_requirement_properties",
    checkPublicRequirementById: "/v1/brand_requirements/public/",
    checkPrivateRequirementById: "/v1/brand_requirements/private/",
  },

  landlords: {
    createLandlord: "/v1/landlord",
    getAllLandlords: "/v1/landlord",
    getAllLandlordProperties: "/v1/properties/landlord/",
    updateLandlord: "/v1/landlord/",
    removeLandlord: "/v1/landlord/",
    getLandlordByID: "/v1/landlord/",
    searchLandlords: "/v1/landlord/search",
    filterLandlords: "/v1/landlord/filter",
    downloadLandlords: "/v1/landlord/download-excel",
  },

  offices: {
    createOffice: "/v1/offices",
    getAllOffices: "/v1/offices",
    getAllOfficeProperties: "/v1/properties/offices",
    updateOffice: "/v1/offices/",
    removeOffice: "/v1/offices/",
    getOfficeByID: "/v1/offices/",
    searchOffices: "/v1/offices/search",
    filterOffices: "/v1/offices/filter",
    downloadOffices: "/v1/offices/download-excel",
  },

  permissions: {
    createPermission: "/v1/permissions",
    getAllPermissions: "/v1/permissions",
    getAllPermissionProperties: "/v1/properties/permissions",
    updatePermission: "/v1/permissions/",
    removePermission: "/v1/permissions/",
    getPermissionByID: "/v1/permissions/",
    searchPermissions: "/v1/permissions/search",
    filterPermissions: "/v1/permissions/filter",
    downloadPermissions: "/v1/permissions/download-excel",
  },

  properties: {
    createProperty: "/v1/properties",
    getAllProperties: "/v1/properties",
    updateProperty: "/v1/properties/",
    removeProperty: "/v1/properties/",
    getPropertyByID: "/v1/properties/",
    searchProperties: "/v1/properties/search",
    filterProperties: "/v1/properties/filter",
    downloadProperties: "/v1/properties/download-excel",
    uploadFileByProperty: "/v1/user-files/upload-properties/",
    uploadVideoFileByProperty: "/v1/user-files/upload-properties-video/",
    uploadVideo: "/v1/user-files/upload-properties-video/",
  },

  roles: {
    createRole: "/v1/roles/",
    getAllRoles: "/v1/roles",
    updateRole: "/v1/roles/",
    removeRole: "/v1/roles/",
    getRoleByID: "/v1/roles/",
    searchRole: "/v1/roles/search",
    filterRole: "/v1/roles/filter",
    downloadRole: "/v1/roles/download-excel",
  },

  summary: {
    getAllSummary: "/v1/summary/all",
  },

  tags: {
    createTag: "/v1/meta/tag",
    getAllTags: "/v1/meta/tag",
    getAllTagProperties: "/v1/properties/tag",
    updateTag: "/v1/meta/tag/",
    removeTag: "/v1/meta/tag/",
    getTagByID: "/v1/meta/tag/",
    searchTag: "/v1/meta/tag/search",
    filterTags: "/v1/meta/tag/filter",
    downloadTags: "/v1/meta/tag/download-excel",
  },

  users: {
    createUser: "/v1/users",
    getAllUsers: "/v1/users",
    getAllUserProperties: "/v1/properties/User",
    updateUser: "/v1/users/",
    removeUser: "/v1/users/",
    getUserByID: "/v1/users/",
    searchUser: "/v1/users/search",
    filterUsers: "/v1/users/filter",
    downloadUsers: "/v1/users/download-excel",
    disable: "/v1/users/toggle-account-status/",
    updateUserProfile: "/v1/users/edit-profile",
    updateUserPassword: "/v1/auth/admin/reset-password/",
    changePassword: "/v1/auth/update-password",
    uploadFile: "/v1/user-files/upload-users/",
    aadharKyc: "/v1/users/trigger/aadhar-kyc",
    aadharOtpVerify: "/v1/users/verify/aadhar-otp",
    notifications: "/v1/notifications",
    readNotifications: "/v1/notifications/read/notification/",
    help: "/v1/users/help-center",
  },

  company: {
    getAllCompany: "/v1/broker_company/current",
    updateCompany: "/v1/broker_company/current",
    getAllSubscriptionById: "/v1/subscription_plans/brokers/",
    getAllSubscription: "/v1/subscription_plans/brokers",
    uploadFile: "/v1/user-files/upload-profileImage/",
  },

  forgotPassword: {
    sendEmail: "/v1/auth/forgot-password",
    verifyOtp: "v1/auth/verify-otp",
    resetPassword: "v1/auth/reset-password",
  },
};
